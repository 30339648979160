import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BroadcastIncidentScreen } from '@core/models/broadcast-incident-screen.model';
import {
  CampaignAbattement,
  ChannelLastOpenedDate,
  JsonCampaignAbattement,
  JsonChannelLastOpenedDate,
  JsonScreenStatus,
  JsonScreenType,
  JsonTvScreen,
  JsonTvScreenHistory,
  JsonTvScreenSpot,
  Screen,
  ScreenStatus,
  ScreenType,
  TvScreen,
  TvScreenHistory,
  TvScreenSpot,
} from '@core/models/screen/screen';
import { BaseService } from '@core/services/base/base.service';
import { environment } from '@env/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlerService, HttpError } from '../error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ScreenService extends BaseService {
  public screenStatus: ScreenStatus[] = [];
  public screenType: ScreenType[] = [];
  public spotsByScreen: TvScreenSpot[] = [];
  public screenHistory: TvScreenHistory[] = [];
  private isLoading = new Subject<boolean>();
  public isLoading$ = this.isLoading.asObservable();
  public reloadList = new Subject<boolean>();

  private updateTotalScreen = new Subject<{ screen: Screen; mode: boolean }>();
  public updateTotalScreen$ = this.updateTotalScreen.asObservable();

  private getScreensData = new Subject<{}>();
  public getScreensData$ = this.getScreensData.asObservable();

  private getScreensPriceLib = new Subject<{}>();
  public getScreensPriceLib$ = this.getScreensPriceLib.asObservable();

  private routePlanScreens = `${environment.api_base_url}/tv/plan-screens`;
  private routeScreens = `${environment.api_base_url}/tv/screens`;
  private routeScreenStatus = `${environment.api_base_url}/tv/screen-status`;
  private routeClosureDate = `${environment.api_base_url}/tv/closure-date`;
  private routeScreenPriceLib = `${environment.api_base_url}/tv/screen-price-lib`;
  private routeScreenDurcalMax = `${environment.api_base_url}/tv/screen-durcalmax`;
  private routeScreenTypes = `${environment.api_base_url}/tv/screen-types`;
  private routeAddScreens = `${environment.api_base_url}/tv/add-new-screens`;
  private routeUpdateScreens = `${environment.api_base_url}/tv/update-screens`;
  private routeDeleteScreens = `${environment.api_base_url}/tv/delete-screens`;
  private routeSpotsByScreen = `${environment.api_base_url}/tv/get-spots-screen`;
  private routeScreenHistory = `${environment.api_base_url}/tv/get-screen-history`;
  private routeLastOpenedScreenDate = `${environment.api_base_url}/tv/last-opened-screen-date`;
  private routeAbattementCampaigns = `${environment.api_base_url}/tv/campaigns-abattements`;
  private routeProductSector = `${environment.api_base_url}/tv/product-sector`;

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  /**
   * Get plan screens according to filter value
   * @param screenFilter (param)
   */
  getPlanScreens(screenFilter: object): Observable<object> {
    const apiBaseUrl = `${this.routePlanScreens}`;
    const params: HttpParams = this.buildParamsApi(screenFilter);

    return this.http.get(apiBaseUrl, { params }).pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Get screens according to filter value
   * @param screenFilter (param)
   */
  getScreens(screenFilter: object): Observable<TvScreen[]> {
    const apiBaseUrl = `${this.routeScreens}`;
    const params: HttpParams = this.buildParamsApi(screenFilter);
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Erreur lors de la récupération des écrans. Veuillez essayer plus tard.',
      },
    ];

    return new Observable<TvScreen[]>(observer => {
      this.http
        .get(apiBaseUrl, { params })
        .pipe(
          map((screens: JsonTvScreen) => {
            this.getScreensData.next(screens);
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Get screens status (ouvert, fermé, inactif, ...)
   * @returns
   */
  getScreenStatus(): Observable<ScreenStatus[]> {
    const apiBaseUrl = `${this.routeScreenStatus}`;
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Erreur lors de la récupération des status écrans. Veuillez essayer plus tard.',
      },
    ];

    return new Observable<ScreenStatus[]>(observer => {
      this.http
        .get(apiBaseUrl, {})
        .pipe(
          map((response: []) => {
            return (this.screenStatus = response.map((status: JsonScreenStatus) => new ScreenStatus(status)));
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Get screen price lib (secteur 1, 2, 3, captif, ...)
   * @param channelId
   * @returns
   */
  getScreenPriceLib(channelParams: object): Observable<[]> {
    const apiBaseUrl = `${this.routeScreenPriceLib}`;
    const params: HttpParams = this.buildParamsApi(channelParams);
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Erreur lors de la récupération des libéllés de tarif. Veuillez essayer plus tard.',
      },
    ];

    return new Observable<[]>(observer => {
      this.http
        .get(apiBaseUrl, { params })
        .pipe(map((screenPriceLib: []) => screenPriceLib))
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  sendScreenPriceLib(screenPriceLib: []): void {
    this.getScreensPriceLib.next(screenPriceLib);
  }

  /**
   * Get screens types (sport, so garanty, ...)
   * @returns
   */
  getScreenTypes(): Observable<ScreenType[]> {
    const apiBaseUrl = `${this.routeScreenTypes}`;
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: "Erreur lors de la récupération des typages d'écran. Veuillez essayer plus tard.",
      },
    ];

    return new Observable<ScreenType[]>(observer => {
      this.http
        .get(apiBaseUrl, {})
        .pipe(
          map((response: []) => {
            return (this.screenType = response.map((type: JsonScreenType) => new ScreenType(type)));
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Get screen durcalmax
   * @param screensInfo
   * @returns
   */
  getScreenDurCalMax(screensInfo: object): Observable<any> {
    const apiBaseUrl = `${this.routeScreenDurcalMax}`;
    const params: HttpParams = this.buildParamsApi(screensInfo);
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: "Erreur lors de la récupération du calibre max de l'écran. Veuillez essayer plus tard.",
      },
    ];

    return new Observable<any>(observer => {
      this.http
        .get(apiBaseUrl, { params })
        .pipe(map(screenDurCalMax => screenDurCalMax))
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Add new screens
   * @param screens object to save
   */
  postScreen(screens: object): Observable<any> {
    const apiBaseUrl = this.routeAddScreens;
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: "Une erreur est survenue lors de la création d'écran. Veuillez essayer plus tard.",
      },
    ];

    return new Observable<any>(observer => {
      this.http
        .post(apiBaseUrl, screens)
        .pipe(map((screenResponse: object) => screenResponse))
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Update screens
   * @param screens object to save
   */
  updateScreen(screens: Array<{}>): Observable<any> {
    const apiBaseUrl = this.routeUpdateScreens;
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: "Une erreur est survenue lors de la modification d'écran. Veuillez essayer plus tard.",
      },
    ];

    return new Observable<any>(observer => {
      this.http
        .put(apiBaseUrl, screens)
        .pipe(map((screenResponse: object) => screenResponse))
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * @param screens
   */
  deleteScreens(screens: Array<{}>): Observable<any> {
    const apiBaseUrl = this.routeDeleteScreens;
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: "Une erreur est survenue lors de la suppression d'écran. Veuillez essayer plus tard.",
      },
    ];

    return new Observable<any>(observer => {
      this.http
        .put(apiBaseUrl, screens)
        .pipe(map((screenResponse: object) => screenResponse))
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Get spots of one screen
   * @returns
   */
  getSpotsByScreen(screen: object): Observable<TvScreenSpot[]> {
    const apiBaseUrl = `${this.routeSpotsByScreen}`;
    const params: HttpParams = this.buildParamsApi(screen);
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: "Erreur lors de la récupération des spots de l'écran. Veuillez essayer plus tard.",
      },
    ];

    return new Observable<TvScreenSpot[]>(observer => {
      this.http
        .get(apiBaseUrl, { params })
        .pipe(
          map((response: []) => {
            return (this.spotsByScreen = response.map((spot: JsonTvScreenSpot) => new TvScreenSpot(spot)));
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Get screen history
   * @returns
   */
  getScreenHistory(screen: object): Observable<TvScreenHistory[]> {
    const apiBaseUrl = `${this.routeScreenHistory}`;
    const params: HttpParams = this.buildParamsApi(screen);
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: "Erreur lors de la récupération des mouvements de l'écran. Veuillez essayer plus tard.",
      },
    ];

    return new Observable<TvScreenHistory[]>(observer => {
      this.http
        .get(apiBaseUrl, { params })
        .pipe(
          map((response: []) => {
            return (this.screenHistory = response.map((spot: JsonTvScreenHistory) => new TvScreenHistory(spot)));
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Get last opened screen date for each channel
   * @param channels (param)
   */
  getLastOpenedScreenDateByChannels(channels: string): Observable<ChannelLastOpenedDate[]> {
    const apiBaseUrl = `${this.routeLastOpenedScreenDate}`;
    const params: HttpParams = this.buildParamsApi({ channels });

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(
        map((jsonLastOpenedScreenDateByChannels: JsonChannelLastOpenedDate[]) =>
          jsonLastOpenedScreenDateByChannels.map(
            (jsonLastOpenedScreenDateByChannel: JsonChannelLastOpenedDate) => new ChannelLastOpenedDate(jsonLastOpenedScreenDateByChannel)
          )
        )
      )
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Get abattements for each campaign
   *
   * @param campaignIds (param)
   */
  getAbattementsByCampaign(campaignIds: string): Observable<CampaignAbattement[]> {
    const apiBaseUrl = `${this.routeAbattementCampaigns}`;
    const params: HttpParams = this.buildParamsApi({
      campaign_ids: campaignIds,
    });

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(
        map((jsonCampaignsAbatts: JsonCampaignAbattement[]) =>
          jsonCampaignsAbatts.map((jsonCampaignAbatt: JsonCampaignAbattement) => new CampaignAbattement(jsonCampaignAbatt))
        )
      )
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Get product sector
   *
   * @param filters
   */
  getProductSector(filters: object): Observable<string> {
    const apiBaseUrl = `${this.routeProductSector}`;
    const params: HttpParams = this.buildParamsApi(filters);

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(map((productSector: string) => productSector))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Event when we need to reset
   */
  changeLoadingState(state: boolean): void {
    this.isLoading.next(state);
  }

  /**
   * Event when select/unselect screen
   */
  eventUpdateTotalScreen(data: { screen: Screen; mode: boolean }): void {
    this.updateTotalScreen.next(data);
  }

  /**
   * Transform filters into params
   * @param filters
   */
  buildParamsApi(filters: object): HttpParams {
    let params = new HttpParams();

    if (filters && typeof filters === 'object') {
      Object.entries(filters).forEach(item => {
        params = params.append(item[0], item[1]);
      });
    }

    return params;
  }

  public getScreensForBroadcastIncident(filter): Observable<BroadcastIncidentScreen[]> {
    let httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Écrans non trouvés.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];
    let link = environment.api_base_url + '/tv/get-screens-for-broadcast-incidents' + '?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.http
        .get(link)
        .pipe(
          map((response: any) => {
            if (response && response.length > 0) {
              return response.map(screen => new BroadcastIncidentScreen(screen));
            }
            return response;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }
}
